/* Template: Info table
   ========================================================================== */

'use strict';

template.info_table = () => {

	const $info_table = $('.info-table--accounts');

	if($info_table.length) {

		$info_table.each(function() {

			const self = $(this),
				desk_table = self.find('.transactions-table__table'),
				desk_table_body = self.find('.transactions-table__table tbody'),
				mobile_table_body = self.find('.transactions-table-mobile'),
				btn_row = self.find('.info-table__btn-row'),
				url = self.find('.more-link').attr('href');
			let count = 0;

			$.ajax({
				url: url,
				success: function(result) {

					let resultCount = 0;

					if(result) {

						$.each(result, function() {

							resultCount++;

							const date = new Date(result[count].DateOfTransactions).toLocaleDateString('en-GB'),
								ref = result[count].Reference;

							let charges = parseFloat(result[count].Value),
								balance = parseFloat(result[count].CFWD);

							if(charges < 0) {
								charges = '-£' + parseFloat((charges * -1)).toFixed(2);
							} else {
								charges = '£' + charges.toFixed(2);
							}

							if(balance < 0) {
								balance = '-£' + parseFloat((balance * -1)).toFixed(2) + ' ' + result[count].BalanceIndicator;
							} else {
								balance = '£' + balance.toFixed(2) + ' ' + result[count].BalanceIndicator;
							}

							desk_table_body.append(`
								<tr>
									<td>${ date }</td>
									<td>${ ref }</td>
									<td class="alignright">${ charges }</td>
									<td class="alignright">${ balance }</td>
								</tr>
							`);

							if(resultCount === 1) {

								mobile_table_body.append(`
									<div class="transactions-table-mobile__row">

										<div class="transactions-table-mobile__heading transactions-table-mobile__heading-main">
											<div class="transactions-table-mobile__main">${ date }</div>
											<div class="transactions-table-mobile__charges">Charges / Credit</div>
											<div class="transactions-table-mobile__balance">Balance</div>
										</div>

										<div class="transactions-table-mobile__content">
											<div class="transactions-table-mobile__main">${ ref }</div>
											<div class="transactions-table-mobile__charges">${ charges }</div>
											<div class="transactions-table-mobile__balance">${ balance }</div>
										</div>

									</div>
								`);

							} else {

								mobile_table_body.append(`
									<div class="transactions-table-mobile__row">

										<div class="transactions-table-mobile__heading transactions-table-mobile__heading-main">
											<div class="transactions-table-mobile__main">${ date }</div>
										</div>

										<div class="transactions-table-mobile__content">
											<div class="transactions-table-mobile__main">${ ref }</div>
											<div class="transactions-table-mobile__charges">${ charges }</div>
											<div class="transactions-table-mobile__balance">${ balance }</div>
										</div>

									</div>
								`);

							}

							count++;
						});

					} else {

						desk_table_body.hide();
						btn_row.addClass('info-table__btn-row--no-transactions');
						desk_table.append('<div class="transactions-no-results">Transaction details aren&apos;t available for this account.</div>').parent('.transactions-table').addClass('transactions-table--no-results');
						mobile_table_body.append('<div class="transactions-no-results">Transaction details aren&apos;t available for this account.</div>');

					}
				}
			});

		});

		// $('.more-link').click(function() {
		//
		// 	const url = $(this).attr('href');
		//
		//
		// });
	}

};
