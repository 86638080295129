/* Template: Page switcher
   ========================================================================== */

'use strict';

template.page_switcher = () => {

	const $select_box = $('select#section-switch');

	$select_box.change(function() {
		window.location = $(this).val();
	});

};
