/* Application environment
   ========================================================================== */

/* eslint-disable no-unused-vars */
/* eslint-disable no-native-reassign */
'use strict';

/**
 * Set up environment globals.
 */
const app = {
	},
	template = {
	};

/**
 * Define jQuery in noConflict mode.
 */
/* eslint-disable no-global-assign */
$ = jQuery.noConflict();
/* eslint-enable no-global-assign */
