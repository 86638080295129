/* Template: Element Link
   ========================================================================== */

'use strict';

template.element_link = function() {

	$('.element-link').click(function() {
		window.location = $(this).find('a').attr('href');
		return false;
	});
};
