/* Template: Toggle
   ========================================================================== */

'use strict';

template.caseToggle = () => {

	const $toggle = $('.caseToggle'),
		toggle = '.caseToggle',
		$toggle_item = $('.toggle-item'),
		toggle_item = '.toggle-item',
		toggle_open = 'toggle-open',
		toggle_parent = '.toggle-parent';

	// Resets
	$toggle.attr('aria-expanded', 'false');
	$toggle.parents(toggle_parent).removeClass(toggle_open);
	$toggle_item.hide();

	$toggle.unbind().click(function(e) {

		const $self = $(this),
			self = this;

		if (!$self.hasClass('toggle--prevent-default')) {
			e.preventDefault();
		}

		$.ajax({
			type: 'GET',
			url: $self.attr('data-ajaxUrl'),
			success: function(data) {
				const $data_toggle = $self.data('toggle'),
					$related_toggle_item = $(toggle_item + '[data-toggle="' + $data_toggle + '"]');
					
				const $appoinments = $related_toggle_item.find('#appoinments');
				const $forms = $related_toggle_item.find('#forms');
				const $messages = $related_toggle_item.find('#messages');

				//var messages = $related_toggle_item.find('#messages');
				$appoinments.html('');
				$forms.html('');
				$messages.html('');
				if (data.Appointments.length === 0) {
					// eslint-disable-next-line no-undef
					$appoinments.html('<p>' + $fNoApp + '</p>');
				} else {
					data.Appointments.forEach(function(appoinment) {
						if (appoinment.Passed) {
							$appoinments.append('<div class="todo__item">' +
								// eslint-disable-next-line no-undef
								'<div class="todo__info">{0} {1}</div>'.f($fpastApp, appoinment.CaseDisplay) +
								'</div>');
						} else {
							$appoinments.append('<div class="todo__item">' +
								// eslint-disable-next-line no-undef
								'<div class="todo__info">{0} {1}</div>'.f($futerApp, appoinment.CaseDisplay) +
								// eslint-disable-next-line no-undef
								'<a href="{0}" class="todo__link">{1}</a>'.f(appoinment.Link, $viewWord) +
								'</div>');
						}
					});
				}

				if (data.Forms.length === 0) {
					// eslint-disable-next-line no-undef
					$forms.html('<p>' + $noForm + '</p>');
				} else {
					data.Forms.forEach(function(form) {
						$forms.append('<div class="todo__item">' +
							'<div class="todo__info">{0}</div>'.f(form.FriendlyName) +
							// eslint-disable-next-line no-undef
							'<a href="{0}" class="todo__link">{1}</a>'.f(form.Link, form.FormComplete ? $viewForm : $FillForm) +
							'</div>');
					});
				}

				if (data.ContactLogs.length === 0) {
					// eslint-disable-next-line no-undef
					$messages.html('<p>' + $noMessage + '</p>');
				} else {
					data.ContactLogs.forEach(function(message) {
						$messages.append('<div class="todo__item">' +
							'<div class="todo__info">Subject: {0}<br/>'.f(message.Subject) +
							'Description: {0}<br/>'.f(message.Description) +
							'Created  on: {0}<br/>'.f(message.CreatedOn) +
							'Method of contact: {0}'.f(message.Subject) +
							'</div>');
					});
				}

				// Resets
				if (!$self.hasClass('toggle--keep-open')) {
					$toggle.not(self).attr('aria-expanded', 'false');
					$toggle.not(self).parents(toggle_parent).removeClass(toggle_open);
					$toggle_item.not($related_toggle_item).hide();
				}

				if ($related_toggle_item.is(':visible')) {

					$self.attr('aria-expanded', 'false');
					$related_toggle_item.hide();
					$self.parents(toggle_parent).removeClass(toggle_open);

				} else {

					$self.attr('aria-expanded', 'true');
					$related_toggle_item.show();
					$self.parents(toggle_parent).addClass(toggle_open);

				}

				// Add test class to element and then remove it to make sure div shows in IE
				$('.info-table__btn-row').addClass('showme').removeClass('showme');
			},
			error: function(data) {
				console.error(data);
			}
		});
	});

	// Enable space and enter for click
	$(document).keydown(function(objEvent) {
		if (objEvent.keyCode === 13 || objEvent.keyCode === 32) {
			$(toggle + ':focus').click();
		}
	});

};
