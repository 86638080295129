/* Template: Edit user
   ========================================================================== */

'use strict';

template.edit_user = () => {

	// Clear subscriptions checkboxes if unsubscribe is selected
	const $subscriptions = $('#subscriptions'),
		$unsubscribe = $('#subscriptions-unsubscribe'),
		$not_unsubscribe = $subscriptions.find('.checkbox-block:not(#subscriptions-unsubscribe)');

	$unsubscribe.click(function() {

		if($(this).hasClass('check-radio-selected')) {
			$subscriptions
				.find('.checkbox-block:not(#subscriptions-unsubscribe)')
				.removeClass('check-radio-selected')
				.find('input[type="checkbox"]')
				.removeAttr('checked')
				.parents('.checkbox-container')
				.removeClass('checkbox-container');
		}
	});

	$not_unsubscribe.click(function() {
		$unsubscribe
			.removeClass('check-radio-selected')
			.find('input[type="checkbox"]')
			.removeAttr('checked')
			.parents('.checkbox-container')
			.removeClass('checkbox-container');
	});

};
