/* Template: Form Scripts
   ========================================================================== */

'use strict';

template.form_scripts = () => {

	const radio_container_class = 'radio-container',
		checkbox_container_class = 'checkbox-container',
		check_radio_selected_class = 'check-radio-selected';

	var applySelectedClass = function($div, $input) {
		var isRadio = $input.attr('type') === 'radio';

		// If it's a radio field, remove from all others with same name
		if (isRadio) {
			var $parent = $input.parents('.' + radio_container_class).first();

			$parent.children('.radio-block').removeClass(check_radio_selected_class);
		}

		if ($input.is(':checked')) {
			$div.addClass(check_radio_selected_class);
		} else if (!isRadio) {
			// Only remove if checkbox, not radio
			$div.removeClass(check_radio_selected_class);
		}
	};

	$('.' + radio_container_class).each(function() {

		// Set class if needed
		$(this).find('input:checked').each(function() {
			applySelectedClass($(this).parents('.radio-block').first(), $(this));
		});

		var running = false;

		$(this).on('click', '> .radio-block', function() {
			if (!running) {
				running = true;

				var $input = $(this).find('input');

				$input.prop('checked', 'checked');
				$input[0].checked = true;

				applySelectedClass($(this), $input);
				$input.trigger('click').trigger('change');
			}
			running = false;
		});
	});

	var $checkboxContainer = $('.' + checkbox_container_class);

	$checkboxContainer.each(function() {

		var running = false;

		// Set class if needed
		$(this).find('input:checked').each(function() {
			applySelectedClass($(this).parents('.checkbox-block').first(), $(this));
		});

		$(this).find('> .checkbox-block').on('click', function() {
			if (!running) {
				running = true;
				var $input = $(this).find('label');

				$input.trigger('click');

				applySelectedClass($(this), $(this).find('input'));
			}
			running = false;
		});

		$(this).find('label').on('click', function(e) {
			e.stopPropagation();
		});
	});

	// Focus on first invalid input on error
	$('input[type=submit]').click(function() {
		$(this).parents('form').find('.input-validation-error').first().focus();
	});

	// Add required field validation
	const requiredError = '<span class="field-validation-error" style="display: block !important;">This field is required</span>',
		fieldValidation = '.field-validation-error',
		validationClass = 'input-validation-error';

	$('.required-field').blur(function() {
		const $this = $(this);

		if(!$.trim(this.value).length) {

			$this.addClass(validationClass);

			if(!$this.siblings('.field-validation-error').length) {
				$this.after(requiredError);
			}

		} else {

			$this.removeClass(validationClass);
			$this.siblings(fieldValidation).remove();

		}

	});
};
