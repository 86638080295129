/* Newport City Homes
   ========================================================================== */

'use strict';

String.prototype.format = String.prototype.f = function() {
	var s = this,
		i = arguments.length;
	console.log(arguments);
	console.log(s);
	while (i--) {
		s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
	}
	return s;
};

$(function() {

	/* Template */
	template.form_scripts();
	template.toggle();
	template.gallery();
	template.page_switcher();
	template.element_link();
	template.page_feedback();
	template.progress_bar();
	template.print();
	template.post_filter();
	template.edit_user();
	template.info_table();
	template.disable_validation();
	template.checkbox_toggle();
	template.radio_toggle();
	template.caseToggle();
	template.appointment_slots();
	template.inputmask();
	template.add_tennant();
	//template.datepicker();
	template.tooltip();
	template.appCancel;

	/* jshint ignore:start */
	// eslint-disable-next-line no-undef
	objectFitImages();
	/* jshint ignore:end */
});

$(window).load(function() {
	template.scroll_to_bottom('.message-window__main');
});

var resizeTimer;
var windowWidth = $(window).width();

$(window).resize(function() {
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function() {
		// Check window width has actually changed and it's not just iOS triggering a resize event on scroll
		if ($(window).width() !== windowWidth) {
			// Update the window width for next time
			windowWidth = $(window).width();

			template.toggle();
		}
	}, 250);
});
