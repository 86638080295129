/* Template: Radio toggle
   ========================================================================== */

'use strict';

template.radio_toggle = () => {

	const $trigger = $('.radio-togglable');

	$trigger.each(function() {

		const $loaded_value = $(this).find('input[type=radio]:checked').val();

		if($loaded_value === 'true') {
			$(this).next('.radio-togglable-item').show();
		} else {
			$(this).next('.radio-togglable-item').hide();
		}

		$(this).find('input[type=radio]').change(function() {

			if(this.value === 'true') {
				$(this).parents('.radio-togglable').next('.radio-togglable-item').show();
			} else {
				$(this).parents('.radio-togglable').next('.radio-togglable-item').hide();
			}
		});

	});

};
