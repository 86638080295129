/* Template: Gallery
   ========================================================================== */

'use strict';

template.gallery = () => {

	const $sliders = $('.gallery-slider');

	$sliders.each(function() {

		const $currentSlider = $(this),
			$imagesSlider = $currentSlider.find('.gallery-slider__images>.slick-init'),
			$thumbnailsSlider = $currentSlider.find('.gallery-slider__thumbnails>.slick-init');

		// images options
		$imagesSlider.slick({
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			cssEase: 'linear',
			fade: true,
			draggable: false,
			asNavFor: $currentSlider.find('.gallery-slider__thumbnails>.slick-init'),
			prevArrow: $currentSlider.find('.gallery-slider__images .prev-arrow'),
			nextArrow: $currentSlider.find('.gallery-slider__images .next-arrow')
		});

		// thumbnails options
		$thumbnailsSlider.slick({
			autoplay: false,
			speed: 300,
			slidesToShow: 5,
			slidesToScroll: 1,
			cssEase: 'linear',
			centerMode: true,
			draggable: false,
			focusOnSelect: true,
			asNavFor: $currentSlider.find('.gallery-slider__images>.slick-init'),
			prevArrow: $currentSlider.find('.gallery-slider__thumbnails .prev-arrow'),
			nextArrow: $currentSlider.find('.gallery-slider__thumbnails .next-arrow'),
			responsive: [
				{
					breakpoint: 720,
					settings: {
						centerMode: true,
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});

		if($thumbnailsSlider.find('.slick-slide').length < 6) {
			$thumbnailsSlider.find('.slick-track').addClass('slick-track--small');
		}

	});

	// Causing accessibility error so removing
	$('.slick-slide').each(function() {
		$(this).removeAttr('aria-describedby');
	});
};
