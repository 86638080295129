/* Template: Datepicker
   ========================================================================== */

'use strict';

template.datepicker = () => {

	var todaysDate = new Date();

	var datepickerOptions = ($('#household-edit-form').length) ? {
		format: 'dd/mm/yyyy',
		zIndex: 1000,
		language: 'en-GB',
		offset: -293,
		endDate: todaysDate
	} : {
		format: 'dd/mm/yyyy',
		zIndex: 1000,
		language: 'en-GB',
		offset: -293
	};

	$('[data-toggle="datepicker"]').datepicker(datepickerOptions);

};
