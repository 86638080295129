/* Template: Appointment slots
   ========================================================================== */

'use strict';

template.appointment_slots = () => {

	let slot,
		time,
		i = 0;

	if(typeof slots !== 'undefined') {
		for (slot in slots) {
			if (slots.hasOwnProperty(slot)) {

				i++;

				$('#appointment-dates').append('<option value="appointment-time-' + i + '">' + slot + '</option>');

				$('#appointment-pick-container .user-detail__content').append('<select class="appointment-time-selector" name="" id="appointment-time-' + i + '"></select>');

				for(time in slots[slot]) {
					if (slots[slot].hasOwnProperty(time)) {

						var time_value = slot + ' ' + slots[slot][time] + ':00';

						$('#appointment-time-' + i).append('<option value="' + time_value + '">' + slots[slot][time] + '</option>');
					}
				}
			}
		}
	}

	$('#AppointmentChoice').val($('#appointment-time-1 option:first-child').val());

	$('.appointment-time-selector:not(#appointment-time-1)').hide();

	$('#appointment-dates').change(function() {

		let date_val = $(this).val(),
			selected_appointment = $('#' + date_val + ' option:first-child').val();

		$('.appointment-time-selector').hide();

		$('#' + date_val).show();

		$('#AppointmentChoice').val(selected_appointment);

	});

	$('.appointment-time-selector').each(function() {

		$(this).change(function() {

			let selected_appointment = $(this).val();

			$('#AppointmentChoice').val(selected_appointment);

		});

	});
};
