/* Template: Scroll to bottom
   ========================================================================== */

'use strict';

template.scroll_to_bottom = (element) => {

	const $element = $(element);

	if($element.length) {
		$element.scrollTop($element[0].scrollHeight);
	}
};
