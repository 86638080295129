/* Template: Checkbox toggle
   ========================================================================== */

'use strict';

template.checkbox_toggle = () => {

	// Hide comments sections
	const $trigger = $('.checkbox-togglable-trigger .checkbox-block');

	$trigger.each(function() {

		if(!$(this).hasClass('check-radio-selected')) {
			$(this).parents('.checkbox-togglable-trigger').next('.checkbox-togglable-item').hide();
		}

	});

	$trigger.click(function() {

		if($(this).hasClass('check-radio-selected')) {
			$(this).parents('.checkbox-togglable-trigger').next('.checkbox-togglable-item').show();
		} else {
			$(this).parents('.checkbox-togglable-trigger').next('.checkbox-togglable-item').hide();
		}

	});

};
