/* Template: Page feedback
   ========================================================================== */

'use strict';

template.page_feedback = function() {

	const $feedback_options = $('.page-feedback__options'),
		$feedback_option = $('.page-feedback__option'),
		feedback_option = '.page-feedback__option',
		$feedback_form = $('#feedback-form'),
		$hidden_feedback_field = $('#pageFeedbackHiddenField'),
		page_feedback = 'page-feedback',
		$yes_label = $('.yes-choice'),
		$no_label = $('.no-choice');

	$feedback_form.not('.open').hide();

	if($feedback_form.hasClass('open')) {

		const $hidden_feedback_field_value = $hidden_feedback_field.val();

		$feedback_options.addClass('contains-selected');

		$(feedback_option + ' span:contains("' + $hidden_feedback_field_value + '")').parent(feedback_option).addClass('selected');

		window.location.hash = page_feedback;
	}

	$yes_label.hide();
	$no_label.hide();

	const $inital_feedback_value = $('.page-feedback__options.contains-selected').find('span').text();

	if($inital_feedback_value === 'No') {
		$no_label.show();
	} else {
		$yes_label.show();
	}

	$feedback_option.click(function() {

		const $feedback_value = $(this).find('span').text();

		$feedback_options.addClass('contains-selected');

		$yes_label.hide();
		$no_label.hide();

		$feedback_form.show();
		$feedback_option.removeClass('selected');
		$(this).addClass('selected');
		$hidden_feedback_field.val($feedback_value);

		if($feedback_value === 'No') {
			$no_label.show();
		} else {
			$yes_label.show();
		}

		window.location.hash = page_feedback;

	});

	// Move required indicators inside labels
	$('.page-feedback .required-indicator').each(function() {
		const $sibling_label = $(this).siblings('label');

		$(this).appendTo($sibling_label);
	});

	// Enable space and enter for click
	$(document).keydown(function(objEvent) {
		if (objEvent.keyCode === 13 || objEvent.keyCode === 32) {
			$(feedback_option + ':focus').click();
		}
	});
};
