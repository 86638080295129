/* Template: Progress bar
   ========================================================================== */

'use strict';

template.progress_bar = function() {

	if($('.progress-bar').length) {

		$('.progress-bar').each(function() {

			const total_steps = $(this).find('.progress-bar-step').length - 1,
				completed_steps = $(this).find('.progress-bar-step--completed').length,
				percentage_complete = (completed_steps / total_steps) * 100;

			$(this).find('.progress-bar__progress').css('width', 'calc(' + percentage_complete + '% - 64px)');

		});

	}

};
