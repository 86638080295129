/* Template: Post filter
   ========================================================================== */

'use strict';

template.post_filter = function() {

	const $filter = $('.post-filter'),
		$filter_select = $('.post-filter select'),
		filter_type = $filter.attr('type');

	$filter_select.change(function() {

		const query_name = $(this).attr('id'),
			query_value = $(this).children(':selected').val(),
			//current_url = window.location.href.split('?')[0],
			base_url = location.origin;

		if(filter_type === 'event') {
			window.location.href = base_url + $filter.attr('eventurl') + '?' + query_name + '=' + query_value;
		} else {
			window.location.href = base_url + $filter.attr('newsurl') + '?' + query_name + '=' + query_value;
		}

	});
};
