/* Template: Disable validation
   ========================================================================== */

'use strict';

template.disable_validation = () => {

	const disable_class = '.disable-validation';

	$(disable_class + ' :input').blur(function() {
		$(this).removeClass('input-valid input-invalid valid');
	});

};
