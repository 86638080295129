/* Template: Add tennant
   ========================================================================== */

'use strict';

template.add_tennant = () => {

	const $addButton = $('#add-household-tennant'),
		$editTennentsFormSave = $('#household-edit-form form #save-members'),
		$newTennentForm = $('#household-new-form'),
		$newTennentFormElements = $newTennentForm.detach();

	$addButton.click(function(e) {
		e.preventDefault();
		$newTennentFormElements.insertBefore($editTennentsFormSave);
		template.datepicker();
		$(this).hide();
	});

};
