/* Template: Datepicker
   ========================================================================== */

'use strict';

template.tooltip = () => {

	var $tooltip = $('.tooltip'),
		$tooltipIcon = $('.tooltip-icon'),
		$tooltipInput = $('.tooltip-container input, .tooltip-container select');

	$tooltipIcon.hover(function() {
		$(this).parents('.field--tooltip').find('.tooltip').attr('aria-hidden', 'false');
	}, function() {
		$(this).parents('.field--tooltip').find('.tooltip').attr('aria-hidden', 'true');
	});

	$tooltipInput.focusin(function() {
		$(this).parents('.field--tooltip').find('.tooltip').attr('aria-hidden', 'false');
	});

	$tooltipInput.focusout(function() {
		$(this).parents('.field--tooltip').find('.tooltip').attr('aria-hidden', 'true');
	});

	$tooltip.each(function() {
		if(!$.trim($(this).html()).length) {
			$(this).prev('.tooltip-container').find('.tooltip-icon').unwrap().remove();
			$(this).unwrap();
		}
	});

};
