/* Template: Print
   ========================================================================== */

'use strict';

template.print = function() {

	$('.btn--print').click(function(e) {
		e.preventDefault();
		window.print();
	});
};
