/* Template: Input mask
   ========================================================================== */

'use strict';

template.inputmask = () => {

	if($('.date-mask').length) {
		$('.date-mask').inputmask('99/99/9999');
	}

};
