/* Template: Toggle
   ========================================================================== */

'use strict';

template.toggle = () => {

	const $toggle = $('.toggle'),
		toggle = '.toggle',
		$toggle_item = $('.toggle-item'),
		toggle_item = '.toggle-item',
		toggle_open = 'toggle-open',
		toggle_open_nav = 'toggle-open-nav',
		toggle_parent = '.toggle-parent';

	// Resets
	$toggle.attr('aria-expanded', 'false');
	$toggle.parents(toggle_parent).removeClass(toggle_open).removeClass(toggle_open_nav);
	$toggle_item.hide();
	$(toggle + '[data-toggle="nav"]').text('Menu');

	$toggle.unbind().click(function(e) {

		const $data_toggle = $(this).data('toggle'),
			$related_toggle_item = $(toggle_item + '[data-toggle="' + $data_toggle + '"]');

		if(!$(this).hasClass('toggle--prevent-default')) {
			e.preventDefault();
		}

		// Resets
		if(!$(this).hasClass('toggle--keep-open')) {
			$toggle.not(this).attr('aria-expanded', 'false');
			$toggle.not(this).parents(toggle_parent).removeClass(toggle_open).removeClass(toggle_open_nav);
			$toggle_item.not($related_toggle_item).hide();
			$(toggle + '[data-toggle="nav"]').not(this).text('Menu');
		}

		if($related_toggle_item.is(':visible')) {

			$(this).attr('aria-expanded', 'false');
			$related_toggle_item.hide();
			$(this).parents(toggle_parent).removeClass(toggle_open);

			if($(this).is('[data-toggle="nav"]')) {
				$(this).text('Menu');
				$(this).parents(toggle_parent).removeClass(toggle_open_nav);
			}

		} else {

			$(this).attr('aria-expanded', 'true');
			$related_toggle_item.show();
			$(this).parents(toggle_parent).addClass(toggle_open);

			if($(this).is('[data-toggle="nav"]')) {
				$(this).text('Close');
				$(this).parents(toggle_parent).addClass(toggle_open_nav);
			}

		}

		// Add test class to element and then remove it to make sure div shows in IE
		$('.info-table__btn-row').addClass('showme').removeClass('showme');

	});

	// Hide toggle-item if click off it
	$(document).on('click', function(e) {

		if (!$(e.target).hasClass('toggle') && !$(e.target).hasClass('toggle-item') && !$(e.target).parents().hasClass('toggle')  && !$(e.target).parents().hasClass('toggle-item')) {
			$('.toggle-item:not(#main-navigation, .toggle-item--no-hide)').hide();
		}

	});

	// Enable space and enter for click
	$(document).keydown(function(objEvent) {
		if (objEvent.keyCode === 13 || objEvent.keyCode === 32) {
			$(toggle + ':focus').click();
		}
	});

};
